import { ControllerParams, IUser } from '@wix/yoshi-flow-editor';

export interface MemoryStorage {
  getItem: (key: string) => string;
  setItem: (key: string, value: string) => void;
}

export interface Storage {
  memory: MemoryStorage;
}

export interface MenuItem {
  id: string;
  link?: string;
  items?: MenuItem[];
  displayCount?: number | null;
  isVisibleInMenuBar?: boolean;
  value?: string | null;
  options?: any[];
  accountNavBar: {
    navBarItems: any[];
    menuItems: any[];
  };
  navBarItems: any[];
  menuItems: any[];
  autoNavigation: boolean;
  onChange?: (funct: EventHandler) => void;
}

interface Event {
  target: {
    value: string;
  };
}

type EventHandler = (event: Event) => void;

export interface Member {
  id: string;
  slug: string;
  loggedIn?: boolean;
}

type Privacy = 'PUBLIC' | 'PRIVATE';

export type MemberData = IUser;

export interface MemberInfo {
  memberId: string;
  memberSlug?: string;
}

export type GetMyMemberResponse = {
  member: {
    id: string;
    contactId: string;
    activityStatus: string;
    privacyStatus: string;
    status: string;
    createdDate: Date;
    updatedDate: Date;
    profile: {
      nickname: string;
      slug: string;
      cover: {
        height: number;
        id: string;
        offsetX: number;
        offsetY: number;
        url: string;
        width: number;
      };
      photo: {
        height: number;
        id: string;
        url: string;
        width: number;
      };
    };
  };
};

export type RolesMap = Record<string, string[]>;

export interface ReturnedRouterData {
  memberData: MemberData & {
    memberContactId: string;
    slugs: {
      primary: boolean;
      name?: string;
    }[];
  };
  privacyStatuses: Record<string, Privacy>;
  roles: RolesMap;
  userSlugOrId: string;
  userId?: string;
}

export type ControllerConfig = ControllerParams['controllerConfig'];

export type Numbers = unknown;

export type WixCodeApi = ControllerConfig['wixCodeApi'];

export type Experiments = ControllerConfig['essentials']['experiments'];

export interface PatternData {
  socialHome: boolean;
  page: string;
  title?: string;
  appData: {
    appDefinitionId: string;
    appPageId?: string;
    menuOrder?: number;
    visibleForRoles?: string[];
    numbers?: {
      key?: string;
    };
  };
  seoData?: {
    title?: string;
    description?: string;
    keywords?: string;
    noIndex?: 'true' | 'false';
  };
}

export interface RouterConfig {
  patterns?: Record<string, PatternData>;
  type?: 'public' | 'private';
  group?: string;
}

export interface RawRouter {
  appDefinitionId: string;
  config: string;
  pages: Record<string, string>;
  prefix: string;
}

export interface Router {
  appDefinitionId: string;
  config: RouterConfig;
  pages: Record<string, string>;
  prefix: string;
}

export type W = (id: string) => MenuItem[];

export interface ConfigItemV1 {
  link: string;
  isVisible: boolean;
  isVisibleMobile: boolean;
  visibleForRoles: string[];
}

export interface ConfigItemV2 {
  l: string;
  hmb: boolean;
  hmmb: boolean;
  vfr: string[];
}

export interface ParsedConfigItem {
  link: string;
  isVisibleInMenuBar: boolean;
  isVisibleInMobileMenuBar: boolean;
  visibleForRoles: string[];
}

export type Callback = (args: any, extra?: any) => any;

export interface SectionData {
  appDefinitionId: string;
  sectionId: string;
  tpaInnerRoute?: string;
  memberId?: string;
}

export interface CacheService {
  hasCurrentUser: (instance: string) => boolean;
  getCurrentUser: (instance: string) => Member | null;
  setCurrentUser: (instance: string, currentUser: Member) => void;
  hasViewedUser: (instance: string, viewedUserId: string) => boolean;
  getViewedUser: (instance: string, viewedUserId: string) => Member | null;
  setViewedUser: (
    instance: string,
    viewedUserId: string,
    viewedUser: Member,
  ) => void;
  hasRoles: (instance: string, viewedUserId: string | undefined) => boolean;
  getRoles: (
    instance: string,
    viewedUserId: string | undefined,
  ) => RolesMap | null;
  setRoles: (
    instance: string,
    viewedUserId: string | undefined,
    rolesMap: RolesMap,
  ) => void;
  hasNumbers: (instance: string, userId: string) => boolean;
  getNumbers: (instance: string, userId: string) => Numbers | null;
  setNumbers: (instance: string, userId: string, numbers: Numbers) => void;
}

export type AppData = {
  counters: { currentUserCounters: number; viewedUserCounters: number };
  permittedPagesMap: Record<string, boolean>;
  parsedRouters: Router[];
  parsedRoutersConfigs: RouterConfig[];
  parsedConfigItems: ParsedConfigItem[];
  currentUserRoles: string[];
  viewedUserRoles: string[];
  publicRouterPrefix: string;
};

export enum Experiment {
  RestrictedMemberPagePermissions = 'specs.membersArea.EnableMemberPagePermissions',
  UseWarmupData = 'specs.membersArea.UseWarmupDataInViewerScript',
  SampleViewerAppFedopsInteractions = 'specs.membersArea.sampleViewerAppFedopsInteractions',
}

export enum WarmupDataKey {
  InitialData = 'initialData',
}
